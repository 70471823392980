.container {
  padding: 0;
}

a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
  background: rgb(251, 253, 255);
}


@media (max-width: 1200px) {
  .section-catalog .container .catalog-container .catalog-wrapper .card {
    width: 205px;
    height: 308px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body .btn {
    width: 100%;
    margin-top: auto;
  }

  .section-catalog .container .catalog-container .catalog-wrapper {
    width: 670px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body .card-text {
    margin-bottom: 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body {
    display: grid;
    align-content: space-between;
  }

  .section-catalog .sidebar-bg-wrapper .sidebar-bg {
    margin-left: 226px;
  }

  .burger-wrapper .burger-container {
    width: 390px;
  }

  .burger-wrapper .burger-container .burger-menu-list {
    margin-top: 50px;
  }

  .burger-wrapper .burger-container .content-buttons {
    margin: 40px 0;
  }

  .burger-wrapper .burger-menu {
    padding: 35px 0 64px 48px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .slider-for {
    width: 260px;
    height: 240px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .main-slide {
    width: 260px;
    height: 230px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .slider-nav {
    width: 243px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .thumb-slide {
    width: 53.59px !important;
    height: 53.59px;
    margin: 0 5.77px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider {
    margin-right: 28px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper h1 {
    font-size: 38px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container {
    margin-top: 70px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-block {
    padding: 34px 56px 54px 58px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-btn {
    width: max-content;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity {
    margin-left: 45px;
    margin-right: 66px;
  }

  .order-page .order-wrapper .user-info .grid-wrapper,
  .order-page .order-wrapper .accordion-one .accordion-item .accordion-body .grid-wrapper {
    grid-template-columns: 324px 324px;
  }

  .order-page .order-wrapper .user-info .grid-wrapper input,
  .order-page .order-wrapper .accordion-one .accordion-item .accordion-body .grid-wrapper input {
    width: 324px;
  }
}

@media (max-width: 992px) {
  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper {
    display: grid;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .slider-for {
    width: 353px;
    height: 291px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .main-slide {
    width: 353px;
    height: 291px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .slider-nav {
    width: 343px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .thumb-slide {
    width: 73.59px !important;
    height: 73.59px;
    margin: 0 9.77px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-block {
    padding: 34px 36px 34px 38px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-image {
    display: none;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-title .text-muted {
    font-size: 15px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity {
    margin-left: 16px;
    margin-right: 48px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .card-header-submit .submit-cost {
    margin-right: 34px;
  }

  .order-page .order-wrapper .user-info .grid-wrapper,
  .order-page .order-wrapper .accordion-one .accordion-item .accordion-body .grid-wrapper {
    grid-template-columns: none;
  }

  .order-page .order-wrapper .total-price .total-price-wrapper .page-underline,
  .order-page .order-wrapper .total-price .total-price-wrapper,
  .order-page .order-wrapper .accordion-one .accordion-item .accordion-body .btn,
  .order-page .order-wrapper .user-info .grid-wrapper input,
  .order-page .order-wrapper .accordion-one .accordion-item .accordion-body .grid-wrapper input {
    width: 100%;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .search-page-adapt {
    font-size: 24px;
  }

  .main-section .container .content-wrapper .content-title h1 {
    font-size: 54px;
  }

  .main-section .container .badge-1 {
    right: 290px;
    top: 42vh;
  }

  .main-section .container .badge-2 {
    right: 40px;
    top: 22vh;
  }

  .main-section .container .badge-3 {
    right: 0px;
    top: 54vh;
  }

  .row-cols-md-3>* {
    width: 50% !important;
  }

  .section-catalog .container .catalog-container .catalog-wrapper {
    width: 420px;
  }

  .section-catalog .sidebar-bg-wrapper .sidebar-bg {
    margin-left: 106px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card {
    width: 195px;
    height: 300px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body {
    padding-top: 10px;
    padding-bottom: 22px;
  }

  /* Footer */
  .footer-navbar {
    height: 257px;
  }

  .footer-navbar #basic-navbar-nav {
    display: flex;
    flex-basis: initial;
    flex-grow: initial;
  }

  .footer-navbar #basic-navbar-nav .scroll-to-top-btn {
    width: 40px;
    height: 40px;
    border-radius: 29.17px;
    border: none;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .footer-navbar #basic-navbar-nav .search-input {
    width: 0;
    opacity: 0;
    margin-right: -20px;
    transition: width 0.3s ease, opacity 0.3s ease;
    margin-left: 10px;
    overflow: hidden;

    color: #000;
  }

  .footer-navbar #basic-navbar-nav .search-input::placeholder {
    color: #525252;
  }

  .footer-navbar #basic-navbar-nav .search-input.visible {
    width: 130px;
    /* Ширина поля поиска, когда оно активно */
    opacity: 1;
    /* Делаем его видимым */
  }

  .footer-navbar #basic-navbar-nav .mx-auto {
    display: none;
  }

  .footer-navbar #basic-navbar-nav .align-items-center {
    display: flex;
    flex-direction: row;
  }

  .footer-navbar .search-form {
    width: max-content;
    background: #fff;
    padding: 0;
    display: flex;
    align-items: center;
  }

  .footer-navbar .search-form .search-btn .icon-search {
    display: none;
  }

  .footer-navbar .search-form .search-btn {
    width: 40px;
    height: 40px;
    background: url('../public/images/icons/cart.svg') no-repeat center / cover;
    border: none;
    cursor: pointer;
  }

  .footer-navbar .footer-menu-underline {
    margin: 20px auto 0px auto;
  }

  .footer-navbar .submenu-buttons {
    margin-top: 24px;
  }
}

@media (max-width: 768px) {
  .section-catalog .container .catalog-container .catalog-wrapper .card {
    width: 155px;
    height: 270px;
  }

  .section-catalog .container .catalog-container .sidebar {
    width: 172px;
  }

  .section-catalog .container .catalog-container .sidebar .sidebar-title {
    font-size: 20px;
    margin-bottom: 13px;
  }

  .section-catalog .container .catalog-container .sidebar .sidebar-list a .sidebar-object {
    font-size: 16px;
    margin-bottom: 18px;
  }

  .burger-wrapper .burger-container .burger-menu-list {
    margin-top: 20px;
  }

  .burger-wrapper .burger-container .burger-menu-list li button {
    font-size: 22px;
  }

  .burger-wrapper .burger-container .burger-menu-list li {
    margin-bottom: 15px;
  }

  .burger-wrapper .burger-container .content-buttons {
    margin: 27px 0;
  }

  .burger-wrapper .burger-container .wrapper-btn h1.wrapper-btn-title {
    font-size: 26px;
  }

  .burger-wrapper .burger-menu {
    padding: 35px 0 54px 18px;
  }

  .burger-wrapper .burger-container {
    width: 210px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper {
    width: 360px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper h1 {
    font-size: 54px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .page-underline {
    width: 100%;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .page-subtitle {
    font-size: 14px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .page-subtitle p {
    margin-bottom: 10px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn h1.wrapper-btn-title {
    font-size: 32px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn {
    margin-top: 30px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider {
    margin-right: 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper .page-underline {
    margin-bottom: 17px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper p {
    margin: 16px 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper ul li button {
    margin: 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper ul {
    display: flex;
    justify-content: space-between;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item {
    padding: 11px 14px 6px 14px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-btn {
    padding: 0 0 0 13px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-title {
    margin-left: 5px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-title h1 {
    font-size: 14px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-title .text-muted {
    font-size: 11px;
    line-height: 14px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity {
    margin-left: 10px;
    margin-right: 36px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .card-header-submit {
    margin-bottom: 11px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-price h5 {
    font-size: 28px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .card-header-submit .submit-cost {
    margin-right: 47px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .d-flex.justify-content-center.mt-3 .btn.btn-primary {
    width: 92.308vw;
    height: 19.487vw;
    border-radius: 5.128vw;
    box-shadow: 0px 0px 7.692vw 0px rgba(0, 32, 50, 0.2);
    background: rgb(255, 255, 255);
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    color: rgb(0, 32, 50);
    font-family: Inter Tight;
    font-size: 5.128vw;
    font-weight: 500;
    line-height: 5.128vw;
    letter-spacing: 0%;
    text-align: center;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .d-flex.justify-content-center.mt-3 .btn.btn-primary img {
    width: 4.615vw;
    margin-left: 2.564vw;
  }

}

@media (max-width: 576px) {
  .header-navbar .container .header-icons .search-form {
    display: none;
  }

  .header-navbar .container .header-icons .header-btn {
    margin: 0;
    width: 13.333vw;
    height: 13.333vw;
  }

  .header-navbar .container .header-icons .header-btn img {
    width: 5.813vw;
  }

  .cart-btn .cart-count-badge {
    width: 5.128vw;
    height: 5.128vw;
    font-size: 3vw;
    line-height: 3vw;
  }

  .main-section .container .badge-wrapper {
    width: 33.985vw;
    height: 7.395vw;
    transform: scaleX(-1);
  }

  .main-section .container .badge-dot {
    width: 4.931vw;
  }

  .main-section .container .badge-wrapper .badge-title-block {
    background-size: 100%;
    padding: 0;
  }

  .main-section .container .badge-wrapper .badge-title-block span {
    font-size: 2.564vw;
    transform: scaleX(-1);
  }

  .main-section .container .badge-1 .badge-dot {
    right: -1vw;
    bottom: -17px;
  }

  .main-section .container .badge-2 .badge-dot {
    left: -1vw;
    bottom: -17px;
  }

  .main-section .container .badge-3 .badge-dot {
    left: -1vw;
    bottom: -17px;
  }

  .main-section .container .badge-wrapper .badge-title-block {
    backdrop-filter: none;
  }

  .main-section .container .badge-1 {
    right: 0;
    left: 6vw;
    top: 36vh;
    transform: scaleX(1);
  }

  .main-section .container .badge-1 .badge-title-block span {
    transform: scaleX(1);
  }

  .main-section .container .badge-2 {
    right: 19vw;
    top: 23vh;
  }

  .main-section .container .badge-3 {
    right: 28vw;
    top: 48vh;
  }

  .main-section .container .content-wrapper .content-title h1 {
    font-size: 10.769vw;
    line-height: 10.769vw;
  }

  .main-section .container .content-wrapper .content-title h1 h1 {
    font-size: 7.669vw;
    line-height: 4.769vw;
    width: auto;
  }

  .main-section {
    padding-bottom: 11.75vw;
  }

  .main-section .container .content-wrapper .content-buttons .main-content-btn {
    width: 51.026vw;
    height: 14.359vw;
    border-radius: 13.333vw;
  }

  .main-section .container .content-wrapper .content-buttons .main-content-btn .btn-text {
    font-size: 4.615vw;
  }

  .main-section .container .content-wrapper .content-buttons .contact-buttons a button,
  .main-section .container .content-wrapper .content-buttons .main-content-btn .btn-icon {
    width: 10.256vw;
    height: 10.256vw;
    border-radius: 10.256vw;
  }

  .main-section .container .content-wrapper .content-buttons .contact-buttons a {
    margin-left: 2.564vw;
  }

  .container .section-header {
    padding-top: 12.821vw;
  }

  .container .section-header .section-wrapper .object-title {
    font-size: 14.872vw;
  }

  .container .section-header .section-wrapper {
    max-width: none;
    width: 100%;
  }

  .container .section-header .wrapper-2 {
    display: none;
  }

  .container .section-header .section-wrapper .object-underline {
    margin-top: 2.564vw;
    margin-bottom: 3.846vw;
    width: 100%;
  }

  .container .section-header .section-wrapper .object-subtitle {
    font-size: 4.103vw;
    line-height: 5.128vw;
    max-width: 72.051vw;
  }

  .section-catalog .container .catalog-container {
    margin-top: 11.538vw;
  }

  .section-catalog .container .catalog-container .sidebar,
  .section-catalog .sidebar-bg-wrapper {
    display: none;
  }

  .row-cols-md-3>* {
    width: 100% !important;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card {
    width: 92.308vw;
    height: 96.41vw;
    margin: 0 auto;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .img-container img {
    height: 48.462vw;
    object-fit: cover;
    border-radius: 5.128vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body {
    padding: 6.41vw 8.974vw 7.692vw 8.974vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body .card-title {
    font-size: 5.641vw;
    line-height: 5.641vw;
    margin-bottom: 1.795vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body .card-text {
    font-size: 5.128vw;
    line-height: 5.128vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .card .card-body .btn {
    width: 74.359vw;
    height: 14.615vw;
    font-size: 4.615vw;
    line-height: 4.615vw;
    margin: 0 auto;
  }

  .section-catalog .container .catalog-container .catalog-wrapper {
    width: 100%;
  }

  .section-catalog {
    padding-bottom: 31.282vw;
  }

  nav.navbar.footer-navbar {
    padding-top: 15.385vw;
    padding-bottom: 13.846vw;
    display: flex;
    height: max-content;
  }

  .footer-navbar .container .nav-brand-wrapper {
    width: max-content;
    position: absolute;
  }

  .footer-navbar .container .nav-brand-wrapper .navbar-brand {
    margin: 0;
  }

  .footer-navbar .container .nav-brand-wrapper .navbar-brand img {
    width: 25.059vw;
  }

  .footer-navbar .search-form {
    height: 13.385vw;
    margin-right: 2.231vw;
  }

  .footer-navbar #basic-navbar-nav .search-input.visible {
    width: 33.333vw;
    margin-right: -5.128vw;
    padding-left: 2.564vw;
    padding-right: 5.228vw;
  }

  .footer-navbar .search-form .search-btn {
    width: 13.385vw;
    height: 13.385vw;
  }

  .footer-navbar #basic-navbar-nav {
    margin-left: auto;
  }

  .footer-navbar .footer-btn.cart-btn {
    width: 13.385vw;
    height: 13.385vw;
  }

  .footer-navbar .footer-btn .icon-shopping-cart {
    width: 5.385vw;
  }

  .footer-navbar #basic-navbar-nav .scroll-to-top-btn {
    width: 13.385vw;
    height: 13.385vw;
    border-radius: 7.479vw;
    margin-left: 2.231vw;
  }

  .footer-navbar #basic-navbar-nav .scroll-to-top-btn img {
    width: 4.615vw;
  }

  .footer-navbar .footer-menu-underline {
    display: none;
  }

  .footer-navbar .footer-submenu {
    order: 4;
  }

  .footer-navbar .submenu-container {
    order: 3;
    margin-top: 12.426vw;
    margin-bottom: 9.744vw;
  }

  .footer-navbar .submenu-buttons {
    margin-top: 0;
  }

  .footer-navbar .footer-submenu .submenu-wrapper {
    margin: 0 auto;
    text-align: center;
    font-size: 3.077vw;
  }

  nav.header-navbar.navbar {
    padding-top: 14.103vw;
    padding-bottom: 6.41vw;
  }

  .header-white .header-logo {
    width: 22.821vw;
    height: 13.167vw;
    background-size: 100%;
  }

  .header-logo {
    width: 22.821vw;
    height: 13.167vw;
    background-size: 100%;
  }

  .burger-wrapper {
    width: 90%;
  }

  .burger-wrapper .burger-menu {
    padding: 6.138vh 3.846vw 4.687vh 8.974vw;
  }

  .burger-wrapper .burger-container {
    width: 100%;
  }

  .burger-wrapper .burger-container .burger-header .burger-logo {
    width: 28.718vw;
    height: 16.505vw;
    background-size: 100%;
  }

  .burger-wrapper .burger-container .burger-header .header-btn {
    width: 13.333vw;
    height: 13.333vw;
  }

  .burger-wrapper .burger-container .burger-header .header-btn img {
    width: 5.813vw;
  }

  .burger-wrapper .burger-container .burger-header .burger-close-btn {
    margin-left: 2.564vw;
  }

  .burger-wrapper .burger-container .burger-menu-list {
    margin-top: 6.32vh;
  }

  .burger-wrapper .burger-container .burger-menu-list li {
    margin-bottom: 2.79vh;
  }

  .burger-wrapper .burger-container .burger-menu-list li button {
    font-size: 8.205vw;
  }

  .burger-wrapper .burger-container .content-buttons {
    margin: 0;
    margin-top: 6.824vh;
    margin-bottom: 5.23vh;
  }

  .burger-wrapper .burger-container .content-buttons .contact-buttons a {
    margin-left: 0;
    margin-right: 3.7vw;
  }

  .burger-wrapper .burger-container .content-buttons .contact-buttons a button {
    width: 14.797vw;
    height: 14.797vw;
    border-radius: 7.4vw;
    background-size: 8vw !important;
  }

  .burger-wrapper .burger-container .content-buttons .contact-buttons a button img {
    width: 28.86px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn h1.wrapper-btn-title {
    font-size: 7.692vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn .main-content-btn {
    width: 64.669vw;
    height: 17.1vw;
    margin-top: 4.359vw;
    border-radius: 13.333vw;
    padding-left: 9.538vw;
    padding-right: 2.564vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn .main-content-btn .btn-text {
    font-size: 5.128vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn .main-content-btn .btn-icon {
    width: 12.874vw;
    height: 12.874vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .wrapper-btn .main-content-btn .btn-icon img {
    width: 7.726vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .custom-accordion .accordion-item .accordion-header .accordion-button {
    font-size: 3.677vw;
    padding: 4.103vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .custom-accordion .accordion-item .accordion-collapse .accordion-body {
    padding: 2.564vw 5.641vw 4.615vw 5.641vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .main-slide {
    width: 92.308vw;
    height: 76.095vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .slider-for {
    width: 92.308vw;
    height: 76.095vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .slider-nav {
    width: 85.308vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .thumb-slide img {
    width: 19.231vw;
    margin: 0 auto;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider .thumb-slide {
    width: 100% !important;
    height: auto;
    margin: 0 auto;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper h1 {
    font-size: 9.231vw;
    margin-bottom: 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper .page-underline {
    margin-top: 2.564vw;
    margin-bottom: 3.846vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper h3 s,
  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper h3 {
    font-size: 7.179vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper p {
    margin: 3.846vw 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper ul li button {
    height: 10.769vw;
    padding: 4.359vw;
    font-size: 4.103vw;
  }

  .product-page-description .cart-ball-animation {
    display: none;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper .btn-add-chart-style {
    width: 92.308vw;
    height: 15.385vw;
    border-radius: 2.051vw;

    font-size: 4.615vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .product-slider-wrapper .btn-add-chart-style img {
    margin-left: 2.564vw;
    width: 4.872vw;
    height: 4.872vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-container-title {
    width: 44.872vw;
    height: 11.795vw;
    border-radius: 5.128vw 5.128vw 0 0;
    font-size: 5.128vw;
    line-height: 6.41vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-block {
    border-radius: 0 5.128vw 5.128vw 5.128vw;
    padding: 8.718vw 6.667vw 16.154vw 6.667vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper h1 {
    font-size: 14.872vw;
    line-height: 17.846vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .page-underline {
    margin-top: 2.564vw;
    margin-bottom: 3.846vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-block h1 {
    font-size: 6.154vw;
    line-height: 7.385vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-block span {
    font-size: 3.59vw;
    line-height: 5.128vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .description-container .description-block p {
    font-size: 3.59vw;
    line-height: 4.359vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-summary {
    font-size: 4.103vw;
    line-height: 4.923vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-btn {
    order: 5;
    position: absolute;
    right: 10.62px;
    bottom: 10.62px;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-image {
    display: initial;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item {
    padding: 2.564vw 4.131vw 7.844vw 4.131vw;
    position: relative;
    border-radius: 2.467vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-image img {
    width: 15.044vw;
    height: 15.044vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-title.col-3 {
    margin-left: 2.097vw;
    width: 29.81vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item h5 {
    font-size: 3.7vw;
    line-height: 4.438vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-title .text-muted {
    font-size: 2.564vw;
    line-height: 3.082vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity .quantity-selector .quantity-selector-nmbr {
    width: 10.056vw;
    height: 10.056vw;
    border-radius: 2.395vw;
    font-size: 3.831vw;
    line-height: 5.987vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity {
    margin-left: 1.923vw;
    margin-right: 4.128vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity .quantity-selector .quantity-selector-buttons {
    margin-left: 1.436vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity .quantity-selector .quantity-selector-buttons button {
    width: 4.79vw;
    height: 4.79vw;
    border-radius: 0.513vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-price {
    margin-right: 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-price h5 {
    font-size: 4.615vw;
    line-height: 4.615vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .row {
    margin: 0;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .card-header-submit .submit-cost {
    margin-right: 4.103vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .card-header-submit .submit-qty {
    margin-right: 9.231vw;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-items .cart-item .align-items-center .card-quantity .quantity-selector {
    margin: 0;
  }

  .chart-wrapper .btn-confirm-wrapper {
    width: 92.308vw;
    height: 15.385vw;
  }

  .chart-wrapper .btn-confirm-wrapper a .btn-confirm {
    font-size: 4.615vw;
    line-height: 4.615vw;
  }

  .order-page .order-wrapper .cart-items-wrapper,
  .order-page .order-wrapper .accordion-item .accordion-header button.accordion-button {
    padding: 0 16px;
  }

  .main-section .control-bar {
    width: 32.715vw;
    height: 0.513vw;
  }

  .main-section .slider-controls {
    right: auto;
    top: initial;
    bottom: 10px;
    display: flex;
    justify-content: center;
    flex-direction: initial;
    margin: 0 15%;
  }

  .main-section .slide.slide-2 .badge-1 {
    right: 0;
    left: 6vw;
    top: 36vh;
  }

  .main-section .slide.slide-2 .badge-2 {
    right: 19vw;
    top: 23vh;
  }

  .main-section .slide.slide-2 .badge-3 {
    right: 28vw;
    top: 48vh;
  }

  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-links a {
    font-size: 4vw;
    margin: 0;
  }
  .section-catalog .container .catalog-container .catalog-wrapper .chart-wrapper .cart-links {
    display: flex;
    justify-content: space-between;
    padding: 0 2vw;
  }
}